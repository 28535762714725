<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-text="$t('passwordRestore.resetPassword')"/>
      <v-spacer/>
      <v-btn
        icon
        @click="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <template v-if="emailIsSent">
          <h3 v-text="$t('passwordRestore.checkYourMail')"/>
          <p>
            {{ $t('passwordRestore.inFewMinutesYouWillReceiveEmail') }}
          </p>
        </template>
        <template v-else>
          <p>
            {{ $t('passwordRestore.pleaseEnterYourRegisteredEmail') }}
          </p>
          <v-text-field
            v-model="emailField"
            filled
            required
            :rules="emailRules"
            :label="$t('passwordRestore.typeYourEmail')"
          />
          <v-btn large block dark @click="submit()" v-text="$t('passwordRestore.restorePassword')"/>
        </template>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "ForgotPassword",
  props: {
    email: { type: Object, required: true },
  },
  emits: ["close"],
  data() {
    return {
      valid: true,
      emailField: this.email,
      emailIsSent: false,
      emailRules: [
        v => !!v || this.$t('errors.fieldRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('errors.errors.notValidEmail'),
      ],
    }
  },
  methods: {
    close() {
      this.emailIsSent = false
      this.$refs.form.reset()
      this.$emit("close")
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$http
          .post(`/auth/users/reset_password/`, { email: this.emailField })
          .then(() => (this.emailIsSent = true))
      }
    },
  },
})
</script>
